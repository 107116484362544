/**
 * Newsletter signup handling
 */

/* eslint-disable prefer-arrow-callback */
module.exports.newsletter = {
    handleSubscribes() {
        $('#subscribe-newsletter form').submit(function handleNewsletterSignUpSubmit(e) {
            e.preventDefault();

            $('#subscribe-newsletter .mg-alert').addClass('uk-hidden');
            $('#subscribe-newsletter form button').addClass('is-waiting');
            $('#subscribe-newsletter form button').attr('disabled', true);

            $.ajax({
                url: `${process.env.MANGOOLS_APP_HOST}/contacts.json`,
                method: 'POST',
                dataType: 'json',
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                data: $.param({
                    contact: {
                        email: $('#subscriber-email').val(),
                        type: 'newsletter',
                        sendgrid_list_id: '14964769'
                    }
                })
            }).done(function handleDone(_data) {
                $('#subscribe-newsletter .mg-alert.is-success').removeClass('uk-hidden');
                $('#subscriber-email').val('');
                dataLayer.push({'event':'newsletter_signup'});
            }).fail(function handleFail(data, _textStatus, _xhr) {
                const res = JSON.parse(data.responseText);
                $('#subscribe-newsletter .mg-alert.is-error').removeClass('uk-hidden');
                $('#subscribe-newsletter .mg-alert.is-error').html(`Email ${res.email}`);
            }).always(function handleAlways() {
                $('#subscribe-newsletter form button').removeClass('is--waiting');
                $('#subscribe-newsletter form button').attr('disabled', false);
            });
        });
    },
    init() {
        this.handleSubscribes();
    }
};
/* eslint-enable prefer-arrow-callback */
