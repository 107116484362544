module.exports = {
    /* eslint-disable prefer-arrow-callback */
    searchForm() {
        $('.mg-searchform').submit(function handleSearchFormSubmit() {
            const keywordInput = $(this).find("input[name='url']");
            const trimmedKeyword = keywordInput.val().trim();
            keywordInput.val(trimmedKeyword);
            return true;
        });

        $('[data-source-switcher] li').on('click', function() {
            var i = $(this).index();
            $('.mg-searchform [name="source_id"]').val(i);
        });
    },

    menuToggle() {

        const wWidth = $(window).width();

        if (wWidth <= 1220) {
            $('#mg-main-nav').attr('aria-hidden', true);
        }

        $('[data-menu-toggle], #mobile-menu-overlay').click(function handleMenuLinkClick() {
            $('#mg-main-nav').toggleClass('is-visible');
            if ($('#mg-main-nav').hasClass('is-visible')) {
                $('body').css('overflow-y', 'hidden');
                $('#mg-main-nav').attr('aria-hidden', false);
                $('[data-menu-toggle="close"]').removeClass('is-hidden');
                $('#mobile-menu-overlay').addClass('uk-active');
                if (myLazyLoad) {
                    myLazyLoad.update();
                }
            } else {
                $('body').css('overflow-y', 'auto');
                $('#mg-main-nav').attr('aria-hidden', true);
                $('[data-menu-toggle="close"]').addClass('is-hidden');
                $('#mobile-menu-overlay').removeClass('uk-active');
            }

        });

        $(window).on('resize', function(event) {
            if ($('#mg-main-nav').hasClass('is-visible')) {
                $('body').css('overflow-y', 'hidden');
                $('#mg-main-nav').attr('aria-hidden', false);
            } else {
                $('body').css('overflow-y', 'auto');
                $('#mg-main-nav').attr('aria-hidden', true);
            }
        });
    },

    clickMenuLink() {
        $(".mg-header a[href*='#']").click(function handleMenuLinkClick() {
            $('#mg-main-nav').removeClass('is-visible');
            $('body').css('overflow-y', 'auto');
            $('#mg-main-nav').attr('aria-hidden', true);
            $('[data-menu-toggle="close"]').addClass('is-hidden');
            $('#mobile-menu-overlay').removeClass('uk-active');
        });
    },

    stickyHeader() {
        if ($('#sticky-header').length) {
            if ($(document).scrollTop() >= 100) {
                $('#sticky-header').addClass('is-scrolled');
            } else {
                $('#sticky-header').removeClass('is-scrolled');
            }
        }
    },

    scrollTopBtn() {

        if ($('.mg-btn.is-scroll-top').length) {
            if ($(document).scrollTop() >= 200) {
                $('.mg-btn.is-scroll-top').addClass('uk-animation-slide-bottom');
            } else {
                $('.mg-btn.is-scroll-top').removeClass('uk-animation-slide-bottom');
            }
        }

    },

    scaleScreenshot() {
        if ($('.mg-screenshot').length) {
            if ($(document).scrollTop() >= $('.mg-screenshot').offset().top - 400) {
                $('.mg-screenshot').addClass('is-scaled');
            } else {
                $('.mg-screenshot').removeClass('is-scaled');
            }
        }
    },

    featureVideo() {

        $('.mg-feature-video-header').on('click', function() {
            $(this).find('svg').toggleClass('uk-hidden');
            $('.mg-feature-video-overlay').toggleClass('uk-hidden');
            $('.mg-feature-video-inner').toggleClass('is-expanded');
        });
    },

    featureVideoScroll() {

        const wWidth = $(window).width();

        if ($('.mg-feature-video').length) {
            if(wWidth > 768) {

                if ($(document).scrollTop() >= $('.mg-feature-video-container').offset().top + 451 - 200) {
                    $('.mg-feature-video-inner').addClass('is-fixed fade-in-right-scale');
                } else {
                    $('.mg-feature-video-inner').removeClass('is-fixed fade-in-right-scale');
                }
            }
        }
    },

    disableInitHide() {
        $('.initial-hide').removeClass('initial-hide');
        $('.initial-hide-mobile').removeClass('initial-hide-mobile');
        $('body').addClass('is-loaded');
    },

    youtubeVideo() {
        /* Ugly fix that will autoplay youtube video after the lightbox open */
        $('a[data-uk-lightbox]').click(function() {
            setTimeout(function() {
                $('.uk-modal iframe')[0].src += '?autoplay=1&list=PLXennlKNpbqhLesybPIZ0VJ849Njt1c3w';
            }, 1000);
        });
    },

    initLazyload() {
        myLazyLoad = new LazyLoad({
            elements_selector: "[data-bg], [data-src]",
            threshold: 400}
        );
    },

    initTooltipster() {
        $('.mg-tooltip').tooltipster();
    },

    init() {

        this.throttledScrollTopBtn = throttle(this.scrollTopBtn, 250);
        window.addEventListener('scroll', this.throttledScrollTopBtn);

        this.throttledStickyHeader = throttle(this.stickyHeader, 250);
        window.addEventListener('scroll', this.throttledStickyHeader);

        this.throttledScaleScreenshot = throttle(this.scaleScreenshot, 250);
        window.addEventListener('scroll', this.throttledScaleScreenshot);

        this.throttledFeatureVideoScroll = throttle(this.featureVideoScroll, 250);
        window.addEventListener('scroll', this.throttledFeatureVideoScroll);


        /* Setting init scroll position when window is scrolled on load */
        this.stickyHeader();

        this.featureVideo();
        this.disableInitHide();
        this.clickMenuLink();
        this.menuToggle();
        this.searchForm();
        this.initLazyload();
        this.initTooltipster();
        this.youtubeVideo();
    }
    /* eslint-enable prefer-arrow-callback */
};
