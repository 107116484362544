
/**
 * Integrated page messages
 */

module.exports.announcements = {
    init(name) {
        $.ajax({
            url: `${process.env.MANGOOLS_APP_HOST}/announcements.json`,
            xhrFields: {
                withCredentials: true
            }
        }).done((data) => {
            if (data.length > 0 && data[0].message_text) {
                let displayTimer = '';

                if (data[0].active_to > 0) {
                    // Set the date we're counting down to
                    const countDownDate = new Date(parseInt(data[0].active_to) * 1000).getTime();

                    // Get todays date and time
                    const now = new Date().getTime();

                    // Find the distance between now an the count down date
                    const distance = countDownDate - now;

                    // Time calculations for days, hours, minutes and seconds
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

                    // If the count down is finished, write some text

                    if (distance > 1) {
                        displayTimer = `Only ${hours} hours left`;
                    } else if (distance == 1) {
                        displayTimer = `Only ${hours} hour left`;
                    } else {
                        displayTimer = 'Offer is expired';
                    }
                }

                displayTimer = '';

                const icon = `<svg class="icon-${data[0].icon} mg-margin-r-10 font-18" aria-hidden="true"><use xlink:href="${name}/assets/images/symbol-defs.svg#icon-${data[0].icon}"></use></svg>`;

                const text = data[0].message_text;

                if (data[0].newtab) {
                    var btn = `<a href="${data[0].url}?ref=annc-kw" class="mg-btn is-green is-xsmall" target="_blank">${data[0].btn_text}</a>`;
                } else {
                    var btn = `<a href="${data[0].url}?ref=annc-kw" class="mg-btn is-green is-xsmall">${data[0].btn_text}</a>`;
                }

                const close = `<button type="button" id="close-message"><svg class="icon-cross font-18" aria-hidden="true"><use xlink:href="${name}/assets/images/symbol-defs.svg#icon-cross"></use></svg></button>`;


                const message = `<aside class="mg-page-message"><div class="uk-container uk-container-center uk-flex uk-flex-middle uk-flex-center">${icon}${text}${btn} ${displayTimer}</div>${close}</aside>`;

                $('body').addClass('messaged');
                $('body').append(message);
            }
        });

        $(document).on('click', '#close-message', () => {
            $('.mg-page-message').addClass('uk-hidden');
            $('body').removeClass('messaged');
        });
    }
};
