module.exports.analytics = {
    init(user) {
        window.dataLayer = window.dataLayer || [];

        // Set campaign params
        if (window.location.hash.length > 0 && window.location.hash.match(/#a/)) {
            dataLayer.push({
                'campaignName': 'affiliate_mangools',
                'campaignSource': document.referrer ? document.referrer : '(direct)',
                'campaignMedium': 'affiliate'
            });
        }

        // Set dimensions
        if (user) {

            //  User created at
            const parseDate = user.created_at.split('-');

             dataLayer.push({
                'user_login_status': 'logged',
                'user_created_at': parseDate[0] + parseDate[1],
                'user_plan': user.plan,
                'user_id': user.id,
                'controller_user_id': user.controller_user_id,
                'user_created_at_i': Date.parse(user.created_at)
            });

        } else {
            dataLayer.push({'user_login_status': 'unlogged'});
        }

        // Init GTM
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=S48cHMmLEmU46lt10FktKg&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KJ9SW4K');

        // Mangools Affiliate Tracking Code
        if (window.location.hash.length === 26 && window.location.hash.match(/#a/)) {
            const affiliate_id = window.location.hash.substring(2);
            let a_id = affiliate_id;

            // Redirected from google SERP with affiliate_id, which is prohibited!
            if (document.referrer && document.referrer.indexOf('google.') > -1) {
                // Log the scammer (original affiliate_id) to GTM.
                dataLayer.push({
                    event: 'prohibited_affiliate_redirect',
                    affiliate_id: affiliate_id
                });

                // And use our mngls acc instead, so we can track the amount of fraudulent PPC affiliates over time.
                a_id = '57c975a9285f7327b801a2b0';
            }

            (new Image()).src = `${process.env.MANGOOLS_APP_HOST}/affiliate/affiliates/set_cookie?affiliate_id=${a_id}&r=${Date.now()}`;
            window.location.hash = '';
        }

        // Mangools Analytics referrer
        const referrer = document.referrer;
        const page = window.location.href;

        (new Image()).src =  `${process.env.MANGOOLS_APP_HOST}/analytics/sources/set_cookie?referrer=` + encodeURIComponent(referrer) + '&page=' + encodeURIComponent(page) + '&r=' + Math.random();
    }
};
